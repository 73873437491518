<script setup lang="ts">
import { useStore } from 'vuex'

withDefaults(defineProps<{
  icon?: string
  iconColor?: Color
  label?: string
  title?: string
  to?: string
  empty?: boolean
  loading?: boolean
  disabled?: boolean
}>(), {
  icon: '',
  title: '',
  empty: false,
  loading: false,
  default: false,
})

const store = useStore()

function closeMenu() {
  if (store.getters.uix?.mobile) {
    setTimeout(() => {
      store.dispatch('updateUI', { sideMenuOpen: false })
    }, 100)
  }
}
</script>

<template>
  <div v-if="empty" class="empty-item">
    &nbsp;
  </div>
  <div v-else class="nav-item" :disabled="disabled">
    <NuxtLink
      :to="to"
      :title="title"
      @contextmenu.prevent
      @click="to ? closeMenu() : undefined"
    >
      <span class="icon-wrap">
        <Icon :name="icon" :style="iconColor ? { color: iconColor } : {}" />
      </span>
    </NuxtLink>
  </div>
</template>

<style lang="scss" scoped>
.nav-item {
  @include noselect;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border-radius: $border-radius-round;
  @media #{$desktop} {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.1rem 0;
  }
  &[disabled='true'] {
    a {
      color: hsl(var(--text-faint)) !important;
      cursor: default !important;
      svg {
        color: hsl(var(--text-faint)) !important;
      }
    }
  }
  &[disabled='false'] {
    @media (hover: hover) {
      &:hover {
        background-color: hsl(var(--element));
      }
    }
  }
  a {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: $border-radius-round;
    @media #{$desktop} {
      width: 2.5rem;
      height: 2.5rem;
    }
    svg {
      vertical-align: middle;
    }
    .icon-wrap {
      color: hsl(var(--text-faint));
    }
    .label {
      margin-top: .15rem;
      font-size: .7rem;
      font-weight: bold;
      color: hsl(var(--text-dim));
    }
    &.exact-active {
      // @media #{$desktop} {
      //   background-color: hsl(var(--element));
      // }
      .icon-wrap,
      .label {
        color: hsl(var(--text));
      }
    }
    @media (hover: hover) {
      &:not(.active):hover {
        .icon-wrap {
          color: hsl(var(--text-dim));
        }
      }
    }
  }
}
</style>
